function resourcePath(link) {
    if ("live2d" in link) return "hash" in link ? "live2d/" + link.hash + "/" + link.live2d : link.base + "/" + link.live2d;
    if (null == link.cover) return "resource/" + link.hash;
    if ("draft_show" in link.cover) return "draft_show/" + link.cover.draft_show + "/cover/" + link.hash;
    if ("show" in link.cover) return "show/" + link.cover.show + "/cover/" + link.hash;
    if ("group" in link.cover) return "group/" + link.cover.group + "/cover/" + link.hash;
    throw Error("invalid cover: " + JSON.stringify(link.cover));
}
function getOssFile(path, process) {
    let ret = BUCKET_ENDPOINT + "/" + path;
    return process ? ret + "!" + process : ret;
}
let BUCKET_ENDPOINT = "https://kastorage2.neka.cc";
export function resolveSector0xLink(uri) {
    if (URL.canParse(uri)) {
        let { protocol, pathname } = new URL(uri);
        return "resource:" === protocol ? getOssFile(resourcePath({
            hash: pathname
        })) : ("market:" === protocol && Error("invalid market resource"), uri);
    }
    return getOssFile(resourcePath({
        hash: uri
    }));
}
