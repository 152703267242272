function splitToFitText(ctx, text, width, totalWidth) {
    let guessText;
    let arr = runes(text), guess = Math.floor(width / totalWidth * arr.length), guessWidth = ctx.measureText(guessText = arr.slice(0, guess).join("")).width;
    if (guessWidth < width) do guess++, guessWidth = ctx.measureText(guessText = arr.slice(0, guess).join("")).width;
    while (guessWidth < width);
    if (guessWidth > width) do {
        let lastSpace = arr.lastIndexOf(" ", guess - 1);
        lastSpace > 0 ? guess = lastSpace : guess--, guessWidth = ctx.measureText(guessText = arr.slice(0, guess).join("")).width;
    }while (guessWidth > width);
    return {
        text: guessText,
        width: guessWidth,
        rest: arr.slice(guess).join("").trimStart()
    };
}
import runes from "runes";
export function tempCanvas(width, height) {
    let canvas = document.createElement("canvas");
    return canvas.width = width, canvas.height = height, {
        canvas,
        ctx: canvas.getContext("2d", {
            willReadFrequently: !0,
            alpha: !0
        })
    };
}
export async function generateImageBlob(width, height, fn) {
    let canvas = document.createElement("canvas");
    return canvas.width = width, canvas.height = height, await fn(canvas.getContext("2d", {
        willReadFrequently: !0,
        alpha: !0
    })), await new Promise((resolve, reject)=>{
        canvas.toBlob((blob)=>{
            blob && resolve(URL.createObjectURL(blob)), reject(null);
        }, "png");
    });
}
export function layoutFirstLine(ctx, text, maxWidth) {
    let line = text.replaceAll(/[^\S\n]+/g, " ").split("\n")[0].trim(), textWidth = ctx.measureText(line).width;
    if (textWidth <= maxWidth) return {
        width: textWidth,
        text: line
    };
    {
        let splited = splitToFitText(ctx, line, maxWidth, textWidth);
        return {
            width: splited.width,
            text: splited.text
        };
    }
}
export function layoutText(ctx, text, maxWidth) {
    let inputLines = text.replaceAll(/[^\S\n]+/g, " ").split("\n").map((x)=>x.trim()).filter(Boolean);
    if (0 === inputLines.length) return {
        width: 0,
        lines: []
    };
    let width = 0, lines = [];
    for (let line of inputLines){
        let textWidth = ctx.measureText(line).width;
        if (textWidth <= maxWidth) width = Math.max(width, textWidth), lines.push(line);
        else {
            for(; textWidth > maxWidth;){
                let splited = splitToFitText(ctx, line, maxWidth, textWidth);
                width = Math.max(width, splited.width), lines.push(splited.text), line = splited.rest, textWidth = ctx.measureText(line).width;
            }
            textWidth > 0 && (width = Math.max(width, textWidth), lines.push(line));
        }
    }
    return {
        width,
        lines
    };
}
