function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
let defaults = {
    experience: 0,
    LDR: 10,
    COM: 10,
    PHY: 10,
    INT: 10,
    SAN: 10,
    KNO: 10,
    master: ""
};
export const StatNames = Object.freeze([
    "LDR",
    "COM",
    "PHY",
    "INT",
    "SAN",
    "KNO"
]);
export const StatInfos = {
    LDR: {
        name: "领导",
        description: "领导精通的角色是光辉的象征，他们的决断如日晷般精准，引领众人穿越风暴与黑暗。",
        tags: [
            "日晷",
            "铁律",
            "战旗",
            "号角",
            "烛台",
            "组织",
            "意志之壁",
            "统御"
        ]
    },
    COM: {
        name: "交流",
        description: "交流精通的角色是语言的魔术师，他们的言辞如同丝绸般柔滑，将思想与情感传递得淋漓尽致。",
        tags: [
            "银舌",
            "心灵之桥",
            "和谐",
            "魅惑",
            "沟通",
            "话术",
            "心声",
            "传达"
        ]
    },
    PHY: {
        name: "体能",
        description: "体能精通的角色用身体与力量开路，他们是风暴中的勇士，在战斗与冒险中书写传奇。",
        tags: [
            "运动",
            "力量",
            "坚石",
            "风暴",
            "生命",
            "爆发",
            "战斗",
            "冒险者"
        ]
    },
    INT: {
        name: "直觉",
        description: "直觉精通的角色拥有无比敏锐的感知力，他们天生能与世界共鸣，情感如泉水般充沛。",
        tags: [
            "创造",
            "神秘",
            "魔法",
            "爱",
            "艺术",
            "共情",
            "灵感",
            "共鸣",
            "奇迹"
        ]
    },
    SAN: {
        name: "理智",
        description: "理智精通的角色如俯瞰世间的星辰，他们在困境中游刃有余，以冷静与理性的力量破解难题。",
        tags: [
            "方程",
            "秩序",
            "天平",
            "思维迷宫",
            "理性之眼",
            "静谧",
            "沉稳"
        ]
    },
    KNO: {
        name: "知识",
        description: "知识精通的角色在书卷间穿行，他们的思想如星图般广阔，揭示真理、点亮前路。",
        tags: [
            "书籍",
            "古塔",
            "智慧之泉",
            "星图",
            "时光之匙",
            "隐秘",
            "光辉",
            "哲理"
        ]
    }
};
export const levelMap = [
    200,
    500,
    900,
    1400,
    2000,
    2700,
    3500,
    4400,
    5400,
    6500,
    7700,
    9000,
    10400,
    11900,
    13500,
    15200,
    17100,
    19100,
    21100,
    23200,
    25400,
    27700,
    30100,
    32600
];
let MAX_EXPERIENCE = levelMap[levelMap.length - 1], MAX_LEVEL = levelMap.length + 1;
export function getExperienceRange(level) {
    return [
        1 === level ? 0 : levelMap[level - 2],
        level >= MAX_LEVEL ? 1 / 0 : levelMap[level - 1]
    ];
}
export function fixupAttributes(input) {
    var experience;
    let filled = _extends({}, defaults, input);
    return _extends({}, filled, {
        experience: Math.min(filled.experience, MAX_EXPERIENCE),
        level: (experience = filled.experience) >= MAX_EXPERIENCE ? MAX_LEVEL : levelMap.findIndex((xp)=>xp > experience) + 1
    });
}
