import { useEffect, useMemo } from "react";
export function useForceLocalCache(key, value) {
    let cached = useMemo(()=>null != value ? value : null == key || "undefined" == typeof window ? null : (()=>{
            let data = localStorage.getItem(key);
            return data ? JSON.parse(data) : null;
        })(), [
        key,
        value
    ]);
    return useEffect(()=>{
        null != key && null != value && localStorage.setItem(key, JSON.stringify(value));
    }, [
        key,
        value
    ]), cached;
}
