function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
import { useUserId } from "$components/common/AuthWrapper/context";
import { GET_ALL_MY_CHARACTERS } from "$frontend/query/user";
import { fixupAttributes } from "$src/attribute";
import { useQuery } from "@apollo/client";
import { toAtom } from "@codehz/form";
import { useMemo } from "react";
import { applyIfNotNull } from "./applyIfNotNull";
import { useForceLocalCache } from "./useForceLocalCache";
export function useAllMyCharacters(filter, fetchPolicy) {
    void 0 === filter && (filter = "everything"), void 0 === fetchPolicy && (fetchPolicy = "cache-first");
    let id = useUserId(), { data: raw } = useQuery(GET_ALL_MY_CHARACTERS, {
        fetchPolicy,
        skip: !id
    }), result = useForceLocalCache(id ? "my-character-" + id : null, raw);
    return useMemo(()=>{
        var _result_sector0x, _item_description_images, _result_user_character, _result_sector0x_characters;
        if (!result) return [];
        let characters_list = null != (_result_user_character = null == result ? void 0 : result.user_character) ? _result_user_character : [], sector0x_characters_list = null != (_result_sector0x_characters = null == result ? void 0 : null == (_result_sector0x = result.sector0x) ? void 0 : _result_sector0x.characters) ? _result_sector0x_characters : [];
        if (0 === characters_list.length) {
            if ("valid" === filter) return [];
            let ret = sector0x_characters_list.map((x)=>{
                var _x_description_images;
                return {
                    id: "sector0x-" + x.id,
                    reference_id: x.id,
                    name: x.description.name,
                    config: {
                        gender: {
                            type: "binary",
                            female: !1
                        }
                    },
                    textures: {
                        avatar: null == (_x_description_images = x.description.images) ? void 0 : _x_description_images.avatar
                    },
                    attributes: fixupAttributes({}),
                    equipments: []
                };
            });
            return ret.unshift({
                id: "new",
                name: "",
                config: {
                    gender: {
                        type: "binary",
                        female: !1
                    }
                },
                textures: {},
                attributes: fixupAttributes({}),
                equipments: []
            }), ret;
        }
        let ret = characters_list.map((_param)=>{
            var { id, reference_id, description: { name, textures }, private_config, attributes, equipments } = _param, config = function(source, excluded) {
                var target, sourceKeys, key, i;
                if (null == source) return {};
                for(i = 0, target = {}, sourceKeys = Object.keys(source); i < sourceKeys.length; i++)excluded.indexOf(key = sourceKeys[i]) >= 0 || (target[key] = source[key]);
                return target;
            }(_param.description, [
                "name",
                "textures"
            ]);
            return {
                id,
                reference_id: null != reference_id ? reference_id : void 0,
                name: name,
                textures,
                config: _extends({
                    gender: {
                        type: "binary",
                        female: !1
                    }
                }, config),
                private_config: applyIfNotNull(private_config, toAtom),
                attributes: fixupAttributes(attributes),
                equipments
            };
        });
        if ("valid" === filter) return ret;
        for (let item of sector0x_characters_list.filter((x)=>!characters_list.some((y)=>x.id === y.reference_id)))ret.push({
            id: "sector0x-" + item.id,
            reference_id: item.id,
            name: item.description.name,
            config: {
                gender: {
                    type: "binary",
                    female: !1
                }
            },
            textures: {
                avatar: null == (_item_description_images = item.description.images) ? void 0 : _item_description_images.avatar
            },
            attributes: fixupAttributes({}),
            equipments: []
        });
        return ret.unshift({
            id: "new",
            name: "",
            config: {
                gender: {
                    type: "binary",
                    female: !1
                }
            },
            textures: {},
            attributes: fixupAttributes({}),
            equipments: []
        }), ret;
    }, [
        result
    ]);
}
